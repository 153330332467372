/* You can add global styles to this file, and also import other style files */
@import "./assets/style/variables";
@import "./assets/style/mixins";
@import "./app/common/med-components/med-mixins/breakpoints-mixins";
@import "./app/common/med-components/med-mixins/breakpoints";
@import "./app/common/med-components/med-colors/colors";

html,
body {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

body.scroll-stop {
  height: 100%;
  overflow: hidden;
}

.wrapper {
  position: relative;
  max-width: $max-main-block-width;
  margin: 0 $wrapper-lg-monitor-margin-width;


  @include xl-breakpoint {
    margin: 0 auto;
  }

  @include lg-breakpoint {
    margin: 0 auto;
  }

  @include md-desktop-breakpoint {
    margin: 0;
  }

  @include xs-breakpoint {
    margin: 0 $wrapper-mobile-margin-width;
  }
}

.section {
  padding-left: $lg-monitor-inner-space;
  padding-right: $lg-monitor-inner-space;

  @include xl-breakpoint {
    padding-left: $xl-monitor-inner-space;
    padding-right: $xl-monitor-inner-space;
  }

  @include md-desktop-breakpoint {
    padding-left: $md-monitor-inner-space;
    padding-right: $md-monitor-inner-space;
  }

  @include xs-breakpoint {
    padding-left: $mobile-monitor-inner-space;
    padding-right: $mobile-monitor-inner-space;
  }
}

@media screen and (max-width: calc($max-main-block-width + $wrapper-lg-monitor-margin-width * 2)) and (min-width: $mdBreakpoint) {
  .wrapper {
    margin: 0 $wrapper-lg-monitor-margin-width;
  }
}

/* ======================== Styling for Non-Webkit Browsers (Firefox etc.) ======================== */
* {
  scrollbar-width: thin;
  scrollbar-color: $blue_500 $neutral_50;
}

/* ======================== Styling for all Webkit Browsers (Chrome, Edge, Safari, etc.) ======================== */

/* width - For Vertical Scrollbar  */
*::-webkit-scrollbar {
  width: 4px;
}

/* height - For Horizontal Scrollbar  */
*::-webkit-scrollbar:horizontal {
  height: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: $neutral_50;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background-color: $blue_500;
  border-radius: 100px;
  @include transition(background-color 0.1s ease);
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background-color: $blue_700;
}

/* ========================= Global Styling for search bar ========================= */
.default-search {
  display: flex;
  gap: 16px;
  background-color: $white;
  
  margin: 0 auto;

  border: 1px solid $neutral_200;
  border-radius: 60px;

  >:first-child {
    margin-left: 16px;
  }

  >:last-child {
    margin-left: auto;
  }
}

.default-input {
  width: 100%;

  outline: none;
  border: none;

  &:focus::placeholder  { color: transparent; }
  &:focus::-webkit-input-placeholder { color:transparent; }
  &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
}

/* ========================= Global Styling for filter menu ========================= */
.modal-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;

  background-color: $black;
  opacity: 0.8;
}

.filter-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;

  width: 484px;
  height: 100vh;
  background-color: $white;

  >:first-child {
    padding: $filter-menu-vertical-padding $filter-menu-horizontal-padding 0;
  }

  >:last-child {
    padding: 0 $filter-menu-horizontal-padding $filter-menu-vertical-padding;
  }


  @include xs-breakpoint {
    top: initial;
    bottom: 0;
    width: 100%;
    height: 90vh;

    border-top-left-radius: $main-border-radius;
    border-top-right-radius: $main-border-radius;

    >:first-child {
      padding: $filter-menu-padding-mobile $filter-menu-padding-mobile 0;
    }
  
    >:last-child {
      padding: 0 $filter-menu-padding-mobile $filter-menu-padding-mobile;
    }
  }
}

.filter {
  display: flex;
  align-items: center;

  border-style: solid;
  border-width: $line-height 0;
  border-color: $neutral_200;

  padding: 24px 0;

  &__button {
    padding: 0;
    margin: 0;    
  }

  &__open {
    margin-right: 40px;
    white-space: nowrap;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
  }

  &__tag {
    margin-right: 16px!important;
    padding: 4px 8px!important;
  }

  &__clear {
    margin-left: auto;
    white-space: nowrap;
  }

  &__separator {
    height: $line-height;
    background-color: $neutral_200;
    margin-top: $line-margin;
  }

  @include xs-breakpoint {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 24px;

    &__tags {
      margin-top: 12px;
    }

    &__separator {
      margin-top: $line-margin-mobile;
    }
  }
}

.menu {
  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    margin: 0;
    padding: $line-margin 0;

    list-style-type: none;
      
    li {
      display: flex;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    position: fixed;
    bottom: 0;
    left: 0;
      
    width: calc(484px - $filter-menu-horizontal-padding * 2);
    height: calc($actions-menu-height - $filter-menu-vertical-padding * 2);

    padding: calc($filter-menu-vertical-padding + 4px) $filter-menu-horizontal-padding;
    background-color: $white;

    border-top: $line-height solid $neutral_200;
    z-index: 105;

    :first-child {
      margin: 0;
    }
  }

  @include xs-breakpoint {
    &__list {
      padding: $line-margin-mobile 0;
    }

    &__actions {
      width: calc(100vw - $filter-menu-padding-mobile * 2);
      height: $actions-menu-height-mobile;
      padding: $filter-menu-padding-mobile;
    }
  }
}

/* ========================= Global Styling for Med-Select-Field ========================= */
.med-select-field {
  position: relative;

  .select-field-label {
      color: $neutral_800;
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
  }

  .select-field-wrapper {
      display: inline-flex;
      align-items: center;
      width: 100%;
      padding: 12px;
      border: 1px solid $neutral_300;
      border-radius: 8px;
      min-width: 100%;
      box-sizing: border-box;
      color: $neutral_900;
      cursor: pointer;

      @include transition(all 0.2s ease);

      &.active {
          outline: 0;
          border-color: $blue_500;
      }

      .selected-text {
          display: inline-flex;
          align-items: center;
          width: 100%;

          &[select-placeholder]:empty:before {
              content: attr(select-placeholder);
              pointer-events: none;
              display: block; 
              color: $neutral_400;
              opacity: 1;
          }
      }
  }

  .med-dropdown-menu {
      position: unset;

      .dropdown-container {
          min-width: 100%;
          transform: unset;
          left: unset;
          right: 0;
      }
  }

  .select-field-text {
      display: inline-flex;
      align-items: flex-start;
      margin-top: 6px;
      text-align: left;

      &.info-text {
          color: $neutral_600;
      }

      &.error-text {
          color: $red_600;

          .input-field-text-icon {
              margin-right: 6px;
              margin-top: 2px;
              img {
                  @include filter(49%, 60%, 807%, 316deg, 95%, 87%);
              }
          }
      }
  }

  .select-field-wrapper {
    .med-dropdown-trigger-element {
        pointer-events: pointer;
    }
  } 

  &.disabled {

      .select-field-wrapper {
          cursor: not-allowed;
          background-color: $neutral_100;
          border-color: $neutral_300;

          .med-dropdown-trigger-element {
              pointer-events: none;
          }
      }
  }
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__section {
    padding: 24px;
    background-color: $blue-50;
    border-radius: 16px;

    >:first-child {
        display: block;
        margin-bottom: 24px;
    }
  }

  @include xs-breakpoint {
    &__section {
      padding: 16px;

      >:first-child {
        margin-bottom: 16px;
      }
    }
  }
}

/* ========================= Global Styling for Page Loader ========================= */
.page-loader {
  height: calc(100vh - 104px);
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}