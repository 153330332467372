$max-main-block-width: 1560px;

$xl-monitor-inner-space: 120px;
$lg-monitor-inner-space: 44px;
$md-monitor-inner-space: 23px;
$mobile-monitor-inner-space: 16px;

$main-border-radius: 16px;

$main-vertical-padding: 144px;
$main-vertical-padding-mobile: 72px;

$wrapper-lg-monitor-margin-width: 16px;
$wrapper-mobile-margin-width: 8px;

/* ========== Filter menu variables ========== */
$filter-menu-horizontal-padding: 44px;
$filter-menu-vertical-padding: 24px;
$filter-menu-padding-mobile: 16px;

$title-block-height: 120px;
$title-block-height-mobile: 112px;

$actions-menu-height: 104px;
$actions-menu-height-mobile: 48px;

$line-margin: 24px;
$line-margin-mobile: 16px;
$line-height: 1px;