@import "_breakpoints";

@mixin xs-breakpoint {
    @media screen and (max-width: $xsBreakpoint) {
        @content;
    }
}

@mixin sm-breakpoint {
    @media screen and (min-width: $xsBreakpoint) and (max-width: $smBreakpoint) {
        @content;
    }
}

@mixin md-breakpoint {
    @media screen and (min-width: $smBreakpoint) and (max-width: $mdBreakpoint) {
        @content;
    }
}

@mixin lg-breakpoint {
    @media screen and (min-width: $mdBreakpoint) and (max-width: $lgBreakpoint) {
        @content;
    }
}

@mixin xl-breakpoint {
    @media screen and (min-width: $lgBreakpoint) {
        @content;
    }
}

@mixin desktop-breakpoint {
    @media (min-width: $smBreakpoint) {
        @content;
    }
}

@mixin mobile-breakpoint {
    @media (max-width: $smBreakpoint) {
        @content;
    }
}

@mixin md-desktop-breakpoint {
    @media (max-width: $mdBreakpoint) {
        @content;
    }
}
