/* ========= Mixins ========= */
@mixin filter($invert, $sepia, $saturate, $hueRotate, $brightness, $contrast) {
    filter: invert($invert) sepia($sepia) saturate($saturate) hue-rotate($hueRotate) brightness($brightness) contrast($contrast);
    -webkit-filter: invert($invert) sepia($sepia) saturate($saturate) hue-rotate($hueRotate) brightness($brightness) contrast($contrast);
    -moz-filter: invert($invert) sepia($sepia) saturate($saturate) hue-rotate($hueRotate) brightness($brightness) contrast($contrast);
    -o-filter: invert($invert) sepia($sepia) saturate($saturate) hue-rotate($hueRotate) brightness($brightness) contrast($contrast);
}

@mixin appearance($val) {
    appearance: $val;
    -webkit-appearance: $val;
    -moz-appearance: $val;
    -ms-appearance: $val;
}

@mixin transition($val) {
    transition: $val;
    -webkit-transition: $val;
    -moz-transition: $val;
    -ms-transition: $val;
}

@mixin transform($val) {
    transform: $val;
    -webkit-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
}

@mixin userSelect($val) {
    user-select: $val;
    -webkit-user-select: $val;
    -moz-user-select: $val;
    -ms-user-select: $val;
}