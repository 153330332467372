@import "./../med-mixins/_mixins.scss";
/* Medatus Color Palette */

/* ========== White ========== */ 
$white: #FFFFFF;

/* ========== Black ========== */
$black: #000000;

/* ========== Neutral / Grey ========== */
$neutral_50: #FBFBFB;
$neutral_100: #F3F3F3;
$neutral_200: #E4E4E4;
$neutral_300: #CFCFCF;
$neutral_400: #A2A2A2;
$neutral_500: #8B8B8B;
$neutral_600: #7E7E7E;
$neutral_700: #636363;
$neutral_800: #4C4C4C;
$neutral_900: #3A3A3A;

/* ========== Pale ========== */
$pale_50: #F7F4F3;
$pale_100: #EEE6E4;
$pale_200: #DFD3D0;

/* ========== Blue ========== */
$blue_50: #ECEDF6;
$blue_100: #D9DCEC;
$blue_200: #C5CAE3;
$blue_300: #9FA7CF;
$blue_400: #6572B3;
$blue_500: #3F4FA0;
$blue_600: #394892;
$blue_700: #2D3872;
$blue_800: #232B58;
$blue_900: #1A2143;

/* ========== Red ========== */
$red_50: #FFF1F0;
$red_100: #FFEAE9;
$red_200: #FFE2E0;
$red_400: #FF9D9B;
$red_500: #FA6E6B;
$red_600: #E46461;
$red_700: #E4504C;

/* ========== Orange ========== */
$orange_500: #FEB342;

/* ========== Green ========== */
$green_50: #F6FFF6;
$green_100: #E6FBE6;
$green_500: #06DF0A;
$green_600: #00C704;
$green_700: #08B90C;

/* ========== Archive ========== */
$archive: #B1B1B1;

/* ========== Sand ========== */
$sand: #E9D9A3;

/* ========== Sky blue ========== */
$sky_blue: #A3C7E9;

/* ========== Grass green ========== */
$grass_green: #B0D2A0;

/* ========== Yellow ========== */
$yellow: #F9DD9B;

/* ========== Pink ========== */
$pink: #F8C9CC;


/* ========== FONT COLORS ======== */
/* ========== White ==========*/
.font-white {
    color: $white;
}

/* ========== Black ==========*/
.font-black {
    color: $black;
}

/* ========== Neutral/Grey ==========*/
.font-neutral-50 {
    color: $neutral_50;
}
.font-neutral-100 {
    color: $neutral_100;
}
.font-neutral-200 {
    color: $neutral_200;
}
.font-neutral-300 {
    color: $neutral_300;
}
.font-neutral-400 {
    color: $neutral_400;
}
.font-neutral-500 {
    color: $neutral_500;
}
.font-neutral-600 {
    color: $neutral_600;
}
.font-neutral-700 {
    color: $neutral_700;
}
.font-neutral-800 {
    color: $neutral_800;
}
.font-neutral-900 {
    color: $neutral_900;
}

/* ========== Pale ==========*/
.font-pale-50 {
    color: $pale_50;
}
.font-pale-100 {
    color: $pale_100;
}
.font-pale-200 {
    color: $pale_200;
}

/* ========== Blue ==========*/
.font-blue-50 {
    color: $blue_50;
}
.font-blue-100 {
    color: $blue_100;
}
.font-blue-200 {
    color: $blue_200;
}
.font-blue-300 {
    color: $blue_300;
}
.font-blue-400 {
    color: $blue_400;
}
.font-blue-500 {
    color: $blue_500;
}
.font-blue-600 {
    color: $blue_600;
}
.font-blue-700 {
    color: $blue_700;
}
.font-blue-800 {
    color: $blue_800;
}
.font-blue-900 {
    color: $blue_900;
}

/* ========== Red ==========*/
.font-red-50 {
    color: $red_50;
}
.font-red-100 {
    color: $red_100;
}
.font-red-200 {
    color: $red_200;
}
.font-red-400 {
    color: $red_400;
}
.font-red-500 {
    color: $red_500;
}
.font-red-600 {
    color: $red_600;
}
.font-red-700 {
    color: $red_700;
}

/* ========== Orange ==========*/
.font-orange {
    color: $orange_500;
}

/* ========== Green ==========*/
.font-green-50 {
    color: $green_50;
}
.font-green-100 {
    color: $green_100;
}
.font-green-500 {
    color: $green_500;
}
.font-green-600 {
    color: $green_600;
}
.font-green-700 {
    color: $green_700;
}

/* ========== Archive ==========*/
.font-archive {
    color: $archive;
}


/* ==================== BACKGROUND COLORS ==================== */

/* ========== White ========== */ 
.bg-white {
    background-color: $white;
}

/* ========== Black ========== */
.bg-black {
    background-color: $black;
}

/* ========== Grass green ========== */
.bg-grass_green {
    background-color: $grass_green;
}

/* ========== Yellow ========== */
.bg-yellow {
    background-color: $yellow;
}

/* ========== Pink ========== */
.bg-pink {
    background-color: $pink;
}

/* ========== Neutral / Grey ========== */
.bg-neutral-50 {
    background-color: $neutral_50;
}
.bg-neutral-100 {
    background-color: $neutral_100;
}
.bg-neutral-200 {
    background-color: $neutral_200;
}
.bg-neutral-300 {
    background-color: $neutral_300;
}
.bg-neutral-400 {
    background-color: $neutral_400;
}
.bg-neutral-500 {
    background-color: $neutral_500;
}
.bg-neutral-600 {
    background-color: $neutral_600;
}
.bg-neutral-700 {
    background-color: $neutral_700;
}
.bg-neutral-800 {
    background-color: $neutral_800;
}
.bg-neutral-900 {
    background-color: $neutral_900;
}

/* ========== Pale ========== */
.bg-pale-50 {
    background-color: $pale_50;
}
.bg-pale-100 {
    background-color: $pale_100;
}
.bg-pale-200 {
    background-color: $pale_200;
}

/* ========== Blue ========== */
.bg-blue-50 {
    background-color: $blue_50;
}
.bg-blue-100 {
    background-color: $blue_100;
}
.bg-blue-200 {
    background-color: $blue_200;
}
.bg-blue-300 {
    background-color: $blue_300;
}
.bg-blue-400 {
    background-color: $blue_400;
}
.bg-blue-500 {
    background-color: $blue_500;
}
.bg-blue-600 {
    background-color: $blue_600;
}
.bg-blue-700 {
    background-color: $blue_700;
}
.bg-blue-800 {
    background-color: $blue_800;
}
.bg-blue-900 {
    background-color: $blue_900;
}

/* ========== Red ========== */
.bg-red-50 {
    background-color: $red_50;
}
.bg-red-100 {
    background-color: $red_100;
}
.bg-red-200 {
    background-color: $red_200;
}
.bg-red-400 {
    background-color: $red_400;
}
.bg-red-500 {
    background-color: $red_500;
}
.bg-red-600 {
    background-color: $red_600;
}
.bg-red-700 {
    background-color: $red_700;
}

/* ========== Orange ========== */
.bg-orange-500 {
    background-color: $orange_500;
}

/* ========== Green ========== */
.bg-green-50 {
    background-color: $green_50;
}
.bg-green-100 {
    background-color: $green_100;
}
.bg-green-500 {
    background-color: $green_500;
}
.bg-green-600 {
    background-color: $green_600;
}
.bg-green-700 {
    background-color: $green_700;
}

/* ========== Archive ========== */
.bg-archive {
    background-color: $archive;
}

/* ==================== ICON COLORS ==================== */

/* ========== White ========== */ 
.icon-white {
    @include filter(100%, 100%, 0%, 288deg, 102%, 102%);
}

/* ========== Black ========== */
.icon-black {
    @include filter(0%, 3%, 6795%, 193deg, 92%, 103%);
}

/* ========== Neutral / Grey ========== */
.icon-neutral-50 {
    @include filter(100%, 96%, 8%, 185deg, 107%, 97%);
}

.icon-neutral-100 {
    @include filter(100%, 2%, 280%, 348deg, 116%, 91%);
}

.icon-neutral-200 {
    @include filter(100%, 0%, 3270%, 320deg, 112%, 79%);
}

.icon-neutral-300 {
    @include filter(100%, 0%, 6822%, 255deg, 112%, 62%);
}

.icon-neutral-400 {
    @include filter(63%, 0%, 1%, 240deg, 105%, 86%);
}

.icon-neutral-500 {
    @include filter(57%, 0%, 161%, 213deg, 97%, 85%);
}

.icon-neutral-600 {
    @include filter(52%, 0%, 19%, 197deg, 94%, 84%);
}

.icon-neutral-700 {
    @include filter(45%, 0%, 0%, 12deg, 82%, 84%);
}

.icon-neutral-800 {
    @include filter(25%, 0%, 1096%, 165deg, 101%, 82%);
}

.icon-neutral-900 {
    @include filter(19%, 23%, 0%, 272deg, 97%, 89%);
}

/* ========== Pale ========== */
.icon-pale-50 {
    @include filter(90%, 65%, 10%, 307deg, 99%, 95%);
}


/* ========== Blue ========== */
.icon-blue-50 {
    @include filter(92%, 2%, 1291%, 198deg, 105%, 93%);
}

.icon-blue-100 {
    @include filter(92%, 6%, 551%, 195deg, 97%, 91%);
}

.icon-blue-200 {
    @include filter(87%, 10%, 493%, 194deg, 94%, 89%);
}

.icon-blue-300 {
    @include filter(72%, 9%, 938%, 193deg, 93%, 85%);
}

.icon-blue-400 {
    @include filter(48%, 14%, 1557%, 193deg, 90%, 85%);
}

.icon-blue-500 {
    @include filter(29%, 14%, 3489%, 198deg, 98%, 91%);
}

.icon-blue-600 {
    @include filter(27%, 24%, 2517%, 202deg, 91%, 89%);
}

.icon-blue-700 {
    @include filter(19%, 81%, 566%, 195deg, 95%, 95%);
}

.icon-blue-800 {
    @include filter(14%, 78%, 920%, 204deg, 90%, 93%);
}

.icon-blue-900 {
    @include filter(9%, 20%, 5319%, 214deg, 89%, 91%);
}

/* ========== Red ========== */
.icon-red-50 {
    @include filter(87%, 2%, 1366%, 314deg, 110%, 101%);
}

.icon-red-100 {
    @include filter(87%, 2%, 2481%, 312deg, 108%, 101%);
}

.icon-red-200 {
    @include filter(89%, 2%, 2244%, 314deg, 96%, 120%);
}
.icon-red-400 {
    @include filter(63%, 21%, 1100%, 312deg, 110%, 124%);
}
.icon-red-500 {
    @include filter(89%, 33%, 6586%, 307deg, 86%, 134%);
}

.icon-red-600 {
    @include filter(44%, 9%, 4608%, 316deg, 114%, 79%);
}

.icon-red-700 {
    @include filter(42%, 9%, 5801%, 319deg, 97%, 93%);
}

/* ========== Orange ========== */
.icon-orange-500 {
    @include filter(70%, 55%, 600%, 341deg, 102%, 99%);
}

/* ========== Green ========== */
.icon-green-50 {
    @include filter(94%, 4%, 451%, 71deg, 106%, 101%);
}

.icon-green-100 {
    @include filter(92%, 23%, 158%, 68deg, 102%, 97%);
}

.icon-green-500 {
    @include filter(51%, 84%, 1850%, 82deg, 108%, 110%);
}

.icon-green-600 {
    @include filter(48%, 89%, 1638%, 84deg, 99%, 111%);
}

.icon-green-700 {
    @include filter(40%, 98%, 861%, 85deg, 102%, 101%);
}
.icon-grass-green {
    @include filter(88%, 14%, 618%, 56deg, 91%, 87%);
}
/* ========== Archive ========== */
.icon-archive {
    @include filter(87%, 0%, 0%, 174deg, 83%, 90%);
}

/* ==================== STROKE COLORS ==================== */

/* ========== White ========== */ 
.stroke-white {
    stroke: $white;
}

/* ========== Black ========== */
.stroke-black {
    stroke: $black;
}

/* ========== Neutral / Grey ========== */
.stroke-neutral-50 {
    stroke: $neutral_50;
}
.stroke-neutral-100 {
    stroke: $neutral_100;
}
.stroke-neutral-200 {
    stroke: $neutral_200;
}
.stroke-neutral-300 {
    stroke: $neutral_300;
}
.stroke-neutral-400 {
    stroke: $neutral_400;
}
.stroke-neutral-500 {
    stroke: $neutral_500;
}
.stroke-neutral-600 {
    stroke: $neutral_600;
}
.stroke-neutral-700 {
    stroke: $neutral_700;
}
.stroke-neutral-800 {
    stroke: $neutral_800;
}
.stroke-neutral-900 {
    stroke: $neutral_900;
}

/* ========== Pale ========== */
.stroke-pale-50 {
    stroke: $pale_50;
}

/* ========== Blue ========== */
.stroke-blue-50 {
    stroke: $blue_50;
}
.stroke-blue-100 {
    stroke: $blue_100;
}
.stroke-blue-200 {
    stroke: $blue_200;
}
.stroke-blue-300 {
    stroke: $blue_300;
}
.stroke-blue-400 {
    stroke: $blue_400;
}
.stroke-blue-500 {
    stroke: $blue_500;
}
.stroke-blue-600 {
    stroke: $blue_600;
}
.stroke-blue-700 {
    stroke: $blue_700;
}
.stroke-blue-800 {
    stroke: $blue_800;
}
.stroke-blue-900 {
    stroke: $blue_900;
}

/* ========== Red ========== */
.stroke-red-50 {
    stroke: $red_50;
}
.stroke-red-100 {
    stroke: $red_100;
}
.stroke-red-200 {
    stroke: $red_200;
}
.stroke-red-400 {
    stroke: $red_400;
}
.stroke-red-500 {
    stroke: $red_500;
}
.stroke-red-600 {
    stroke: $red_600;
}
.stroke-red-700 {
    stroke: $red_700;
}

/* ========== Orange ========== */
.stroke-orange-500 {
    stroke: $orange_500;
}

/* ========== Green ========== */
.stroke-green-50 {
    stroke: $green_50;
}
.stroke-green-100 {
    stroke: $green_100;
}
.stroke-green-500 {
    stroke: $green_500;
}
.stroke-green-600 {
    stroke: $green_600;
}
.stroke-green-700 {
    stroke: $green_700;
}

.stroke-grass-green {
    stroke: $grass_green;
}

/* ========== Archive ========== */
.stroke-archive {
    stroke: $archive;
}